<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <el-button
        type="primary"
        @click="
          $router.push($options.ADDEVENT_ADMIN_ROUTES.CATALOG.SOLUTIONS.CREATE)
        "
      >
        Создать готовое решение
      </el-button>
    </div>
    <el-table :data="solutions" stripe @sort-change="onSortChange">
      <el-table-column label="Фото">
        <template slot-scope="scope">
          <div :class="$style.image">
            <img
              :src="setupImagesListData(scope.row?.images).original"
              :alt="setupImagesListData(scope.row?.images).caption"
              @error="setDefaultImage"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Название" sortable />
      <el-table-column width="130">
        <template slot-scope="scope">
          <ActionButtons
            name="trash"
            :edit-link="
              getRoute({
                route: $options.ADDEVENT_ADMIN_ROUTES.CATALOG.SOLUTIONS.UPDATE,
                params: { id: scope?.row?.id },
              })
            "
            @delete="deleteSolution(scope?.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="totalPages > 1"
      background
      layout="prev, pager, next"
      :page-size="query.limit"
      :total="count"
      :current-page.sync="query.page"
      :class="$style.pagination"
      @current-change="getSolutions"
    >
    </el-pagination>
  </div>
</template>
<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'
import images from '@/mixins/images.js'

import {
  ADDEVENT_ADMIN_ROUTES,
  getRoute,
  ADDEVENT_ROUTES,
} from '@/constants/routing'
import { EL_TABLE_SORTING_TYPES } from '@/constants/common'
import { SORTING_TYPES } from '@/constants/common'
import { PROJECTS } from '@/constants/projects'

import ActionButtons from '@/components/atoms/ActionsButtons.vue'

export default {
  components: { ActionButtons },
  ADDEVENT_ADMIN_ROUTES,
  EL_TABLE_SORTING_TYPES,
  ADDEVENT_ROUTES,
  PROJECTS,
  mixins: [notifications, images],
  data() {
    return {
      solutions: [],
      query: {
        page: this.$route.query.page ?? 1,
        limit: 12,
        orderBy: this.$route.query.orderBy ?? 'created_at',
        orderDir: this.$route.query.orderDir ?? SORTING_TYPES.DESC,
      },
      count: 0,
    }
  },
  async created() {
    await this.getSolutions()
  },
  async updated() {
    if (JSON.stringify(this.$router.query) !== JSON.stringify(this.query))
      this.$router.push({query: this.query})
  },
  computed: {
    totalPages() {
      return Math.ceil(this.count / this.query.limit)
    },
  },
  methods: {
    async onSortChange({ prop, order }) {
      if (prop && order) {
        this.query.orderBy = prop
        this.query.orderDir = EL_TABLE_SORTING_TYPES[order] ?? null
      }
      this.page = 1
      await this.getSolutions()
    },
    async getSolutions() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.SolutionsActions.getList({
          ...this.query,
        })

      loading.close()

      if (error) {
        return
      }

      this.solutions = value?.data ?? []
      this.count = value?.meta?.count ?? 0
    },
    async deleteSolution(solution) {
      const isConfirm = confirm(
        `Вы точно хотите удалить готовое решение: ${solution.name}`,
      )

      if (!isConfirm) return

      const loading = this.$loading({
        lock: true,
      })

      const { error } =
        await delivery.AddeventServiceCore.SolutionsActions.delete(solution.id)

      loading.close()

      if (error) {
        this.showNotification('Ошибка удаления готового решения', 'error')
        return
      }

      this.showNotification('Готовое решение успешно удалено', 'success')
      await this.getSolutions()
    },
    getRoute({ route, params, site }) {
      return getRoute({ route, params, site })
    },
  },
}
</script>
<style lang="scss" module>
.page {
  padding: 1rem;

  .wrapper {
    @include stickyWrapper;
    margin-bottom: 1rem;
  }
  .image {
    width: 12rem;
    height: 10rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .pagination {
    @include pagination;
  }
}
</style>
